import React, { ComponentProps, FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

import { useColorMode, ColorMode } from 'providers/colorMode';
import BrowserFrame from 'components/browserFrame';

interface QueryResult {
  allFile: {
    nodes: Array<
      IGatsbyImageData & {
        relativePath: string;
      }
    >;
  };
}

function NoFrame({ children }: { children: JSX.Element; mode: ColorMode }) {
  return children;
}

const Container = styled.div<{
  $fullwidth: boolean;
  $margin: number;
}>`
  ${({ theme: { rhythm, width }, $fullwidth, $margin }) => `
    &[class^='image__Container'] {
      margin: ${rhythm($margin)} auto;
      ${$fullwidth ? `max-width: inherit;` : ''}
      ${$fullwidth ? `padding: inherit;` : ''}
      @media (min-width: ${width}px) {
        ${$fullwidth ? 'width: 100%' : ''};
      }
    }
  `}
`;

const ContainerInner = styled.div<{
  $width: number;
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 90%;
  position: relative;
  @media (min-width: 480px) {
    max-width: ${({ $width }) => $width}%;
  }
`;

const Caption = styled.p`
  ${({ theme: { rhythm } }) => `
    font-size: 80%;
    font-style: italic;
    margin: ${rhythm(1)} auto 0;
    opacity: 0.5;
    text-align: center;
  `}
`;

const BoxFrame = styled.div<{ mode: ColorMode }>`
  ${({ theme: { colorScheme }, mode }) => `
     box-shadow: 0 0 ${
       mode === ColorMode.DARK
         ? '12px hsla(0 0% 0% / 8%)'
         : '28px hsla(0 0% 0% / 8%)'
     };
     ${
       mode === ColorMode.DARK
         ? `border: 1px solid ${colorScheme.hr.background};`
         : ''
     }
  `}
`;

interface Props {
  alt: string;
  caption?: string;
  frame?: 'box' | 'browser' | 'mobile' | 'none';
  fullwidth?: boolean;
  margin?: number;
  title?: string;
  src: string;
  width?: number;
}

function Image({
  alt,
  caption,
  frame = 'none',
  fullwidth = false,
  margin = 2,
  title,
  src,
  width = 90,
}: Props) {
  const { mode } = useColorMode();
  const Frame = {
    box: BoxFrame as FC<ComponentProps<typeof BrowserFrame>>,
    browser: BrowserFrame,
    mobile: BrowserFrame,
    none: NoFrame,
  }[frame];

  const file: IGatsbyImageData = useStaticQuery<QueryResult>(graphql`
    query {
      allFile(
        filter: {
          relativePath: { regex: "/.(webp|png|jpg|jpeg)/" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `).allFile.nodes.find(({ relativePath }) => relativePath === src)!;
  return (
    <Container $margin={margin} $fullwidth={fullwidth}>
      <ContainerInner $width={fullwidth ? 100 : width}>
        <Frame mode={mode}>
          <GatsbyImage alt={alt} image={getImage(file)!} title={title} />
        </Frame>
        {caption && <Caption>{caption}</Caption>}
      </ContainerInner>
    </Container>
  );
}

export default Image;
