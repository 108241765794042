import type { PrismTheme } from 'prism-react-renderer';

const theme: PrismTheme = {
  plain: {
    color: '#24292e',
    backgroundColor: 'transparent',
  },
  styles: [
    {
      types: ['comment', 'prolog', 'doctype', 'cdata'],
      style: {
        color: '#999988',
      },
    },
    {
      types: ['namespace'],
      style: {
        opacity: 0.7,
      },
    },
    {
      types: ['line-number'],
      style: {
        opacity: 0.4,
      },
    },
    {
      types: ['string', 'attr-value'],
      style: {
        color: '#032f62',
      },
    },
    {
      types: ['builtin', 'class-name', 'maybe-class-name', 'constant'],
      style: {
        color: '#24292e',
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: '#393A34',
      },
    },
    {
      types: [
        'entity',
        'url',
        'symbol',
        'number',
        'boolean',
        'variable',
        'property',
        'regex',
        'inserted',
      ],
      style: {
        color: '#36acaa',
      },
    },
    {
      types: ['atrule', 'keyword', 'attr-name'],
      style: {
        color: '#00a4db',
      },
    },
    {
      types: ['keyword', 'deleted'],
      style: {
        color: '#d73a49',
      },
    },
    {
      types: ['function', 'arrow', 'operator'],
      style: {
        color: '#6f42c1',
      },
    },
    {
      types: ['tag', 'selector'],
      style: {
        color: '#24292e',
      },
    },

    {
      types: ['bold'],
      style: {
        fontWeight: '600',
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      languages: ['bash'],
      types: [
        'prolog',
        'doctype',
        'cdata',
        'string',
        'attr-value',
        'builtin',
        'class-name',
        'maybe-class-name',
        'constant',
        'punctuation',
        'entity',
        'url',
        'symbol',
        'number',
        'boolean',
        'variable',
        'property',
        'regex',
        'inserted',
        'atrule',
        'keyword',
        'attr-name',
        'keyword',
        'deleted',
        'function',
        'arrow',
        'operator',
        'tag',
        'selector',
      ],
      style: {
        color: '#24292e',
      },
    },
  ],
};

export default theme;
