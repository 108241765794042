import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDev,
  faGithub,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import colors from 'lib/colors/minimal';
import Image from 'components/image';

const Container = styled.div<{ $centerImage: boolean }>`
  ${({ theme: { width }, $centerImage }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (min-width: ${width}px) {
      flex-direction: row;
      ${!$centerImage ? 'align-items: flex-start' : ''};
    }
  `}
`;

const LeftColumn = styled.div`
  ${({ theme: { rhythm, width } }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (min-width: ${width}px) {
      margin-bottom: ${rhythm(1)};
      margin-right: ${rhythm(1)};
    }
  `}
`;

const RightColumn = styled.div``;

const ImageContainer = styled.div`
  ${({ theme: { rhythm } }) => `
    margin-top: ${rhythm(1 / 2)};
    margin-bottom: ${rhythm(1 / 2)};
    width: ${rhythm(4)};
  `}
`;

const LinksContainer = styled.div``;

const SocialLink = styled.a`
  background: none;
  color: ${colors.C4};
  font-size: ${({ theme: { rhythm } }) => rhythm(2 / 3)};
  margin: 0 4px;
  text-decoration: none;
`;

interface Props {
  centerImage?: boolean;
  children: ReactNode | ReactNode[];
  hideLinks?: boolean;
}

function Avatar({ children, centerImage = false, hideLinks = false }: Props) {
  return (
    <Container $centerImage={centerImage}>
      <LeftColumn>
        <ImageContainer>
          <Image
            alt="Me"
            margin={0}
            src="me.webp"
            title="Me, laughing out loud"
            width={100}
          />
        </ImageContainer>
        {!hideLinks && (
          <LinksContainer>
            <SocialLink href="https://www.linkedin.com/in/jexperton/">
              <FontAwesomeIcon icon={faLinkedin} />
            </SocialLink>
            <SocialLink href="https://github.com/jexperton">
              <FontAwesomeIcon icon={faGithub} />
            </SocialLink>
            <SocialLink href="https://dev.to/jexperton">
              <FontAwesomeIcon icon={faDev} />
            </SocialLink>
          </LinksContainer>
        )}
      </LeftColumn>
      <RightColumn>{children}</RightColumn>
    </Container>
  );
}

export default Avatar;
