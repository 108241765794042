import React, { createElement } from 'react';
import { MDXProvider } from '@mdx-js/react';

import '@fontsource/fira-code/400.css';
import '@fontsource/inter/variable-full.css';
import 'assets/fonts/quattro/style.css';

import Link from 'components/link';
import Gist from 'components/gist';
import Image from 'components/image';
import Video from 'components/video';
import Layout from 'components/layout';
import Note from 'components/note';
import Pre from 'components/pre';
import Separator from 'components/separator';
import * as Anchor from 'components/anchor';

const components = {
  a: Link,
  anote: Anchor.Note,
  gist: Gist,
  hr: Separator,
  img: Image,
  video: Video,
  note: Note,
  pre: Pre,
};

export const wrapRootElement = ({ element }) => {
  return createElement(MDXProvider, { components }, element);
};

export const wrapPageElement = ({ element, props }) => {
  return createElement(Layout, props, element);
};
