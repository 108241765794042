import styled from 'styled-components';

const Separator = styled.hr<{ top?: number; bottom?: number }>`
  ${({ theme: { rhythm, width }, bottom = 2, top = 2 }) => `
    &[class^="separator__Separator"] {
      align-self: center;
      height: 1px;
      margin: ${rhythm(top)} auto ${rhythm(bottom)};
      max-width: 30%;
      width: ${width / 2}px;
    }
  `}
`;

export default Separator;
