export default {
  C1: '#2e3440',
  C2: '#4c566a',
  C3: '#eceff4',
  C4: '#8fbcbb',
  C5: '#88c0d0',
  C6: '#81a1c1',
  C7: '#5e81ac',
  C8: '#bf616a',
  C9: '#d08770',
  C10: '#ebcb8b',
  C11: '#a3be8c',
  C12: '#b48ead',
};
