import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  margin-left: -3rem;
  opacity: 0.4;
`;

const A = styled.a`
  color: inherit;
  background-image: inherit;
  ${Icon} {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }
  &:hover > ${Icon} {
    opacity: 0.3;
  }
`;

interface Props {
  children: ReactNode | ReactNode[];
  id: string;
}

function Link({ children, id }: Props) {
  return <a href={`#${id}`}>{children}</a>;
}

function Note({ id }: { id: string }) {
  return (
    <sup>
      &thinsp;
      <a href={`#note-${id}`} id={`note-${id}-link`}>
        {id}
      </a>
    </sup>
  );
}

function Target({ children, id }: Props) {
  return (
    <A id={id} href={`#${id}`}>
      <Icon icon={faLink} />
      {children}
    </A>
  );
}

export { Link, Note, Target };
