import React, { ReactNode, createContext, useContext, useMemo } from 'react';

enum LangCode {
  EN = 'en',
  FR = 'fr',
}

interface Navigation {
  lang: LangCode;
  menu: (MenuItem & { active: boolean })[];
  slug?: string;
  translation?: string;
}

const NavigationContext = createContext<Navigation>({
  lang: LangCode.EN,
  menu: [],
});

interface PageContext {
  frontmatter?: {
    cover: string;
    description: string;
    menus: Record<string, { identifier: string }>;
    meta?: {
      description?: string;
      title?: string;
    };
    promoted?: boolean;
    published?: boolean;
    publishedAt?: string;
    title?: string;
    subtitle?: string;
  };
  langKey: LangCode;
  menus: Record<string, MenuItem[]>;
  slug: string;
}

interface MenuItem {
  identifier: string;
  title: string;
  url: string;
  weight: number;
}

function getPageMenuItem(pageContext: PageContext) {
  return pageContext.menus[pageContext.langKey].find(
    (item) =>
      (pageContext.slug.length > 4 ? pageContext.slug.substring(4) : '/') ===
      `${item.url.substring(4)}/`
  );
}

function getPageTranslation(pageContext: PageContext) {
  const menuItem = getPageMenuItem(pageContext);
  return (
    (menuItem &&
      pageContext.menus[
        pageContext.langKey === LangCode.EN ? LangCode.FR : LangCode.EN
      ].find(
        (translation) =>
          translation.identifier.split('_')[0] ===
          menuItem.identifier.split('_')[0]
      )) ||
    null
  );
}

interface Props {
  children: ReactNode;
  pageContext: PageContext;
}

function NavigationProvider({ children, pageContext }: Props): JSX.Element {
  const slug = pageContext.slug.replace(/^\/(.+)\/$/g, '$1');
  const value = useMemo(
    () => ({
      lang: pageContext.langKey,
      menu: pageContext.menus[pageContext.langKey].map((item) => ({
        ...item,
        active: item.url.replace(/^\/?(.+)\/?$/g, '$1') === slug,
        slug: pageContext.slug,
      })),
      translation: getPageTranslation(pageContext)?.url,
    }),
    [pageContext]
  );
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
}

const useNavigation = () => useContext(NavigationContext);

export type { PageContext };
export { NavigationContext, useNavigation };
export default NavigationProvider;
