import React, { useContext, useMemo, useState, ReactNode } from 'react';

enum ColorMode {
  DARK = 'dark',
  LIGHT = 'light',
}

const ColorModeContext = React.createContext<{
  mode: ColorMode;
  setMode: (value: ColorMode) => void;
}>({
  mode: ColorMode.LIGHT,
  setMode: () => {},
});

interface Props {
  children: ReactNode;
}

const STORAGE_KEY = 'ColorMode';

function ColorSchemeProvider({ children }: Props): JSX.Element {
  const [mode, setModeValue] = useState(ColorMode.LIGHT);

  const value = useMemo(
    () => ({
      mode,
      setMode: (id: ColorMode) => {
        setModeValue(id);
      },
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
}

const useColorMode = () => useContext(ColorModeContext);

export { ColorMode, ColorModeContext, useColorMode };
export default ColorSchemeProvider;
