import React from 'react';
import styled from 'styled-components';

import { useTypography } from 'providers/typography';
import Separator from 'components/separator';
import PublicationDate from 'components/publicationDate';
import AboutMe from 'components/aboutMe';
import Section from 'components/section';
import Image from 'components/image';

const Title = styled.h1`
  margin-top: 0;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-style: oblique 10deg;
  margin-top: 0;
  text-align: center;
`;

const PublicationDateContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme: { rhythm } }) => rhythm(2)};
`;

const AboutMeTitle = styled.h3`
  color: ${({ theme: { colorScheme } }) => colorScheme.headers.color};
  text-align: center;
  text-transform: uppercase;
`;

const DraftMessage = styled.p`
  ${({ theme: { rhythm, colorScheme } }) => `
   background-color: ${colorScheme.note.background};
   border: 1px solid ${colorScheme.hr.background};
   border-radius: 3px;
   color: ${colorScheme.note.color};
   margin: ${rhythm(1)} 0;
   padding: ${rhythm(1 / 2)};
  `}
`;

interface Props {
  children: JSX.Element;
  cover?: string;
  publishedAt: string;
  subtitle?: string;
  title: string;
  published?: boolean;
}

function Layout({
  children,
  cover,
  published,
  publishedAt,
  subtitle,
  title,
}: Props) {
  const typography = useTypography();
  return (
    <Section>
      {cover && <Image alt="cover image" src={cover} width={100} />}
      <PublicationDateContainer>
        <PublicationDate
          date={publishedAt}
          locale="en-CA"
          options={{ month: 'long' }}
        />
      </PublicationDateContainer>
      <>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Separator />
      </>
      {!published && (
        <DraftMessage>
          This post is a work in progress and hasn&apos;t been published yet.
        </DraftMessage>
      )}
      {children}
      <Separator />
      <AboutMeTitle>About me</AboutMeTitle>
      <AboutMe>
        <h3 style={{ marginTop: typography.rhythm(1) }}>
          Hi, I&apos;m Jonathan Experton.
        </h3>
        <p>
          <em>
            I help companies start, plan, execute and deliver{' '}
            <strong>software development projects</strong> on time, on scope and
            on budget.
          </em>
        </p>
      </AboutMe>
    </Section>
  );
}

export default Layout;
