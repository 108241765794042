import React, { useState, useEffect } from 'react';

import * as Anchor from 'components/anchor';
import styled from 'styled-components';

const Container = styled.p`
  color: ${({ theme: { colorScheme } }) => colorScheme.note.color};
  font-size: 0.8rem;
  text-indent: -0.6rem;
`;

const ContainerInner = styled.span<{ $active: boolean }>`
  ${({ theme: { colorScheme }, $active }) => `
    ${$active ? `background-color: ${colorScheme.note.background}` : ''}
  `}
`;

interface Props {
  id: string;
  children: string | JSX.Element | Array<string | JSX.Element>;
}

function Note({ id, children }: Props): JSX.Element {
  const [active, setActive] = useState(false);
  const eventHandler = () => {
    if (typeof window === 'undefined') return;
    setActive(window.location.hash === `#note-${id}`);
  };

  if (typeof window !== 'undefined')
    window.addEventListener('hashchange', eventHandler);

  useEffect(() => () => {
    if (typeof window !== 'undefined')
      window.removeEventListener('hashchange', eventHandler);
  });

  return (
    <Container id={`note-${id}`}>
      <ContainerInner $active={active}>
        <sup>{id}</sup> {children}{' '}
        <Anchor.Link id={`note-${id}-link`}>↑</Anchor.Link>
      </ContainerInner>
    </Container>
  );
}

export default Note;
