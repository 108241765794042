import React, { ReactNode } from 'react';
import styled from 'styled-components';

export enum BackgroundColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

const SectionContainer = styled.section<{
  $background: BackgroundColor;
  $minHeight?: number;
  $textAlign: TextAlign;
}>`
  ${({ theme: { rhythm, colorScheme, width }, $background, $textAlign }) => `
    background-color: ${
      colorScheme.section.background[
        $background === BackgroundColor.LIGHT ? 'lighter' : 'darker'
      ]
    };
    ${
      $background === BackgroundColor.DARK
        ? 'box-shadow: inset 0px 0px 5px hsla(0, 0%, 0%, .02);'
        : ''
    }
    overflow: hidden;
    transition: background-color 1s ease-out;

    > *, > iframe, > table {
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: ${$textAlign};
      width: 100%;

      @media (min-width: ${width}px) {
        width: ${width}px;
        margin-left: auto;
        margin-right: auto;
      }

      &:first-child {
        margin-top: ${rhythm(2)}
      }

      &:last-child {
        margin-bottom: ${rhythm(2)}
      }
    }

    > ol {
      padding-left: 3rem;
    }

    > blockquote {
      padding-left: 2rem;
      padding-right: 2rem;
    }


  `}
`;

const SectionContainerMinHeight = styled(SectionContainer)`
  ${({ theme: { rhythm, width }, $minHeight }) => `

    @media (min-width: ${width}px) {
      display: flex;
      align-items: center;
      min-height: ${rhythm($minHeight)};
    }
  `}
`;

interface Props {
  background?: BackgroundColor;
  children: ReactNode | ReactNode[];
  id?: string;
  minHeight?: number;
  textAlign?: TextAlign;
}

function Section({
  background = BackgroundColor.LIGHT,
  children,
  id,
  minHeight,
  textAlign = TextAlign.LEFT,
}: Props) {
  const Container = minHeight ? SectionContainerMinHeight : SectionContainer;
  return (
    <Container
      id={id}
      $background={background}
      $minHeight={minHeight}
      $textAlign={textAlign}
    >
      {children}
    </Container>
  );
}

export default Section;
