import colors from 'lib/colors/minimal';
import type { ColorScheme } from 'providers/colorScheme';

const scheme: ColorScheme = {
  body: {
    color: colors.C0,
    background: colors.C12,
  },
  headers: {
    color: colors.C2,
  },
  hr: { background: colors.C8 },
  a: {
    color: colors.C5,
    code: {
      background: colors.C10,
      hover: {
        background: colors.C8,
      },
    },
  },
  code: {
    background: colors.C10,
  },
  note: {
    background: 'hsl(60, 89%, 89%)',
    color: colors.C4,
  },
  th: {
    border: colors.C7,
  },
  td: {
    border: colors.C9,
  },
  blockquote: {
    border: 'hsl(0, 0%, 90%)',
    background: colors.C11,
  },
  section: {
    background: {
      lighter: colors.C12,
      darker: colors.C11,
    },
  },
  pre: {
    background: 'hsla(0, 0%, 0%, .02)',
    color: colors.C0,
    highlighted: {
      background: 'hsla(0, 0%, 0%, .05)',
    },
  },
  location: {
    color: colors.C4,
    marker: { color: colors.C5 },
  },
};

export default scheme;
