import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-foreword/"
        }}>{`Introduction: Some reasons to build a PaaS with Kubernetes and GitOps practices`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part1"
        }}>{`Part I: Cluster setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part2"
        }}>{`Part II: Gitlab pipeline and CI/CD configuration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part3"
        }}>{`Part III: Applications and the Dockerfile`}</a></li>
      <li parentName="ul">{`Part IV: Kubernetes manifests`}</li>
      <li parentName="ul">{`Caveats and improvements to a PaaS built with Kubernetes and GitOps practices`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      