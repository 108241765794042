import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import lightScheme from 'lib/schemes/light';
import { ColorMode, useColorMode } from 'providers/colorMode';

interface ColorScheme {
  a: {
    color: string;
    code: { background: string; hover: { background: string } };
  };
  body: {
    background: string;
    color: string;
  };
  headers: { color: string };
  hr: { background: string };
  code: { background: string };
  note: { background: string; color: string };
  th: { border: string };
  td: { border: string };
  blockquote: { background: string; border: string };
  section: {
    background: {
      lighter: string;
      darker: string;
    };
  };
  pre: {
    background: string;
    color: string;
    highlighted: {
      background: string;
    };
  };
  location: {
    color: string;
    marker: { color: string };
  };
}

const ColorSchemeContext = createContext<ColorScheme>(lightScheme);

interface Props {
  children: ReactNode;
  schemes: Record<ColorMode, ColorScheme>;
}

function ColorSchemeProvider({ children, schemes }: Props): JSX.Element {
  const { mode } = useColorMode();
  const value = useMemo(() => schemes[mode], [mode]);
  return (
    <ColorSchemeContext.Provider value={value}>
      {children}
    </ColorSchemeContext.Provider>
  );
}

const useColorScheme = () => useContext(ColorSchemeContext);

export type { ColorScheme };
export { ColorSchemeContext, useColorScheme };
export default ColorSchemeProvider;
