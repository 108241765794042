import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import Typography from 'typography';
import verticalRhythm from 'compass-vertical-rhythm';

import lightScheme from 'lib/schemes/light';
import type { ColorScheme } from 'providers/colorScheme';
import { useColorScheme } from 'providers/colorScheme';
import { ColorMode, useColorMode } from 'providers/colorMode';

const underline = (background: string, color: string) => ({
  textShadow: [
    `2px 0 0 ${background}`,
    `1px 0 0 ${background}`,
    `-1px 0 0 ${background}`,
    `-2px 0 0 ${background}`,
  ].join(', '),
  backgroundImage: [
    'linear-gradient(to top, rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0) 2px',
    `${color} 3px`,
    'rgba(0, 0, 0, 0) 2px)',
  ].join(', '),
  textDecoration: 'none',
});

const getTypography = (scheme: ColorScheme, mode: ColorMode) =>
  new Typography({
    scaleRatio: 2,
    baseFontSize: '18px',
    baseLineHeight: 2,
    bodyColor: scheme.body.color,
    bodyFontFamily: ['InterVariable', 'sans-serif'],
    bodyWeight: 400,
    headerFontFamily: ['InterVariable', 'sans-serif'],
    headerWeight: 630,
    overrideStyles: ({ adjustFontSizeTo, rhythm }, options) => {
      const vr = verticalRhythm({
        baseFontSize: '18px',
        baseLineHeight: '36px',
      });
      return {
        html: {
          ...vr.establishBaseline(),
        },
        body: {
          background: scheme.body.background,
          color: scheme.body.color,
          transition: 'color 1s, background 1s ease-in',
        },
        a: {
          color: scheme.a.color,
          transition: 'color 1s, text-shadow 1s ease-out',
          ...underline(scheme.body.background, scheme.a.color),
        },
        '.underline': {
          ...underline(scheme.body.background, scheme.body.color),
        },
        'span.highlight': {
          color: 'inherit',
        },
        'a:hover,a:active': {
          backgroundImage: 'none',
          textShadow: 'none',
        },
        'a>code:hover': {
          background: scheme.a.code.hover.background,
        },
        'h1,h2,h3,h4,h5,h6': {
          color: scheme.headers.color,
          marginTop: rhythm(2),
          lineHeight: 'inherit',
        },
        hr: {
          backgroundColor: scheme.hr.background,
          transition: 'background-color 1s ease-out',
        },
        code: {
          background: scheme.code.background,
          borderRadius: '3px',
          fontFamily:
            "'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
          lineHeight: 'inherit',
          padding: '.2rem .4rem',
          textShadow: 'none',
          whiteSpace: 'nowrap',
        },
        pre: {
          ...adjustFontSizeTo('18px'),
          fontFamily:
            "'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
          marginBottom: 0,
        },
        thead: {
          fontFamily: 'Roboto, sans-serif',
        },
        'table td': { borderColor: scheme.td.border },
        'table th': { borderColor: scheme.th.border },
        "td[align='center']": {
          textAlign: 'center',
        },
        'ol,ul': {
          marginLeft: 0,
        },
        'ol>li,ul>li': {
          marginLeft: '1rem',
          marginBottom: '1rem',
        },
        'li > :last-child': {
          marginBottom: rhythm(1),
        },
        'em,blockquote': {
          fontVariationSettings: "'slnt' 16",
        },
        blockquote: {
          background: scheme.blockquote.background,
          borderLeft: `4px solid ${scheme.blockquote.border}`,
          fontSize: '1.1rem',
          fontStyle: 'italic',
          lineHeight: rhythm(1),
          margin: `${rhythm(1)} 0`,
          padding: `${rhythm(1)} ${rhythm(1)}`,
        },
        'blockquote cite': {
          ...adjustFontSizeTo(options.baseFontSize),
          color: options.bodyColor,
          display: 'block',
          fontStyle: 'normal',
          fontWeight: options.bodyWeight,
          marginTop: rhythm(1),
        },
        'blockquote cite:before': {
          content: '"— "',
        },
        '@media (max-width: 480px)': {
          blockquote: {
            borderLeft: `${rhythm(3 / 16)} solid ${scheme.a.color}`,
            fontStyle: 'italic',
            marginLeft: rhythm(-3 / 4),
            marginRight: 0,
            paddingLeft: rhythm(9 / 16),
          },
        },
      };
    },
  });

const TypographyContext = createContext<Typography>(
  getTypography(lightScheme, ColorMode.LIGHT)
);

interface Props {
  children: ReactNode;
}

function TypographyProvider({ children }: Props): JSX.Element {
  const { mode } = useColorMode();
  const colorScheme = useColorScheme();
  const typography = useMemo(() => getTypography(colorScheme, mode), [
    colorScheme,
  ]);
  return (
    <TypographyContext.Provider value={typography}>
      {children}
    </TypographyContext.Provider>
  );
}

const useTypography = () => useContext(TypographyContext);

export { useTypography, TypographyContext };
export default TypographyProvider;
