import type { PrismTheme } from 'prism-react-renderer';
import colors from 'lib/colors/nord';

const theme: PrismTheme = {
  plain: {
    color: colors.C3,
    backgroundColor: 'transparent',
  },
  styles: [
    {
      types: ['comment', 'prolog', 'doctype', 'cdata'],
      style: {
        color: '#616E88',
      },
    },
    {
      types: ['namespace'],
      style: {
        opacity: 0.7,
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: colors.C3,
      },
    },
    {
      types: ['property', 'constant', 'symbol', 'deleted'],
      style: {
        color: colors.C6,
      },
    },
    {
      types: ['number'],
      style: {
        color: colors.C4,
      },
    },

    {
      types: ['boolean'],
      style: {
        color: colors.C6,
      },
    },
    {
      types: ['selector', 'char', 'builtin', 'inserted'],
      style: {
        color: colors.C11,
      },
    },
    {
      types: ['operator', 'entity', 'url', 'variable'],
      style: {
        color: colors.C6,
      },
    },
    {
      types: ['string'],
      style: {
        color: colors.C11,
      },
    },
    {
      types: ['atrule', 'attr-name'],
      style: {
        color: colors.C6,
      },
    },
    {
      types: ['function'],
      style: {
        color: colors.C3,
      },
    },
    {
      types: ['class-name', 'maybe-class-name'],
      style: {
        color: colors.C5,
      },
    },
    {
      types: ['keyword'],
      style: {
        color: colors.C6,
      },
    },
    {
      types: ['regex', 'important'],
      style: {
        color: colors.C10,
      },
    },
    {
      types: ['important'],
      style: {
        fontWeight: 'bold',
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      types: ['entity'],
      style: {
        cursor: 'help',
      },
    },
    {
      types: ['*'],
      languages: ['css'],
      style: {
        color: colors.C6,
      },
    },
  ],
};

export default theme;
