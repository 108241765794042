import React from 'react';
import { ThemeProvider } from 'styled-components';

import lightScheme from 'lib/schemes/light';
import darkScheme from 'lib/schemes/dark';
import NavigationProvider from 'providers/navigation';
import ColorModeProvider, { ColorMode } from 'providers/colorMode';
import type { PageContext } from 'providers/navigation';
import ColorSchemeProvider, { ColorSchemeContext } from 'providers/colorScheme';
import TypographyProvider, { TypographyContext } from 'providers/typography';
import Head from 'components/head';
import Menu from 'components/menu';
import Section from 'components/section';
import Article from 'components/article';

const TABLET_WIDTH = 640;

function NoSection({ children }: { children: JSX.Element }) {
  return children;
}

interface Props {
  children: JSX.Element;
  pageContext: PageContext;
}

function Layout({ children, pageContext }: Props) {
  const isBlog = /^\/[a-z]{2}\/blog\/?/.test(pageContext.slug);
  const isBlogArticle = /^\/[a-z]{2}\/blog\/.+/.test(pageContext.slug);
  const Wrapper = isBlog ? Section : NoSection;
  return (
    <NavigationProvider pageContext={pageContext}>
      <ColorModeProvider>
        <ColorSchemeProvider
          schemes={{
            [ColorMode.DARK]: darkScheme,
            [ColorMode.LIGHT]: lightScheme,
          }}
        >
          <ColorSchemeContext.Consumer>
            {(colorScheme) => (
              <TypographyProvider>
                <TypographyContext.Consumer>
                  {(typography) => (
                    <ThemeProvider
                      theme={{
                        ...typography,
                        colorScheme,
                        width: TABLET_WIDTH,
                      }}
                    >
                      <Head pageContext={pageContext} />
                      <Section>
                        <Menu />
                      </Section>
                      {isBlogArticle ? (
                        <Article
                          cover={pageContext.frontmatter!.cover}
                          published={pageContext.frontmatter!.published}
                          publishedAt={pageContext.frontmatter!.publishedAt!}
                          subtitle={pageContext.frontmatter!.subtitle}
                          title={pageContext.frontmatter!.title!}
                        >
                          {children}
                        </Article>
                      ) : (
                        <Wrapper>{children}</Wrapper>
                      )}
                    </ThemeProvider>
                  )}
                </TypographyContext.Consumer>
              </TypographyProvider>
            )}
          </ColorSchemeContext.Consumer>
        </ColorSchemeProvider>
      </ColorModeProvider>
    </NavigationProvider>
  );
}

export default Layout;
