import React from 'react';
import styled from 'styled-components';
import Highlight, { defaultProps } from 'prism-react-renderer';

import prismThemeLight from 'lib/prism/prismGithub';
import prismThemeDark from 'lib/prism/prismNord';
import prismDockerfile from 'lib/prism/components/prismDockerfile';
import prismShellSession from 'lib/prism/components/prismShellSession';
import { useColorMode, ColorMode } from 'providers/colorMode';
import CopyButton from 'components/copy';

const CopyButtonContainer = styled.div`
  opacity: 0;
  position: absolute;
  right: 2rem;
  top: 1rem;
  transition: opacity 0.1s ease-in-out;
`;

const Container = styled.div`
  ${({ theme: { colorScheme, rhythm, width } }) => `
    background-color: ${colorScheme.pre.background};
    box-shadow: inset 0px 0px 5px hsla(0, 0%, 0%, .02);
    display: grid;
    margin: ${rhythm(2)} 0;
    max-width: inherit;
    padding: ${rhythm(2)} 0 0;
    position: relative;
    width: 100%;
    @media (min-width: ${width}px) {
        grid-template-columns: auto ${width}px auto;
      }
    }
  `}

  &:hover {
    ${CopyButtonContainer} {
      opacity: 1;
    }
  }
`;

const ContainerInner = styled.div`
  ${({ theme: { rhythm } }) => `
    overflow: auto;
    padding-bottom: ${rhythm(2)};
  
    pre {
      display: table;
      font-size: .9rem;
      min-width: 100%;
      > div {
        display: table-row;
      }
    }
  `}
`;

const Line = styled.div<{ $highlighted: boolean }>`
  ${({ theme: { colorScheme }, $highlighted }) => `
    ${
      $highlighted
        ? `background-color: ${colorScheme.pre.highlighted.background}; font-weight: 600;`
        : ''
    }
    display: flex;
    min-width: 100%;
  `}
  padding: 0 1rem;
`;

const LineNumber = styled.div`
  ${({ theme: { colorScheme } }) => `
    opacity: 0.3;
    width: 0;

    + .token {
      padding-left: 1rem;
      margin-left: 2.5rem;
      border-left: 1px solid ${colorScheme.hr.background};
    }
  `}
`;

const LinePaddingContainer = styled.div`
  ${({ theme: { width } }) => `
    display: none;
    @media (min-width: ${width}px) {
        display: block;
      }
    }
  `}
`;

const LinePadding = styled.div<{ $highlighted: boolean }>`
  flex: 1;
  height: 2rem;
  width: 100%;
  ${({ theme: { colorScheme }, $highlighted }) =>
    $highlighted
      ? `background-color: ${colorScheme.pre.highlighted.background};`
      : ''}
`;

function parseLineNumbers(value: unknown): number[] {
  if (typeof value !== 'string') return [];
  return value
    .split(',')
    .filter((item: string) => /^\d+$/.test(item))
    .map((item: string) => +item);
}

interface Props {
  children: JSX.Element;
}

function Pre({ children }: Props) {
  const { mode } = useColorMode();
  const showLineNumbers: boolean = children.props.lineNumbers || false;
  const highlightedLines = parseLineNumbers(children.props.lines);
  const matches = (children.props.className || '').match(
    /language-(?<lang>.*)/
  );
  const prismTheme = {
    [ColorMode.DARK]: prismThemeDark,
    [ColorMode.LIGHT]: prismThemeLight,
  }[mode];

  (defaultProps.Prism.languages as any).Dockerfile = prismDockerfile(
    defaultProps.Prism
  );
  (defaultProps.Prism.languages as any).shell = prismShellSession(
    defaultProps.Prism
  );

  return (
    <Container>
      <CopyButtonContainer>
        <CopyButton value={children.props.children} />
      </CopyButtonContainer>
      <Highlight
        Prism={defaultProps.Prism}
        theme={prismTheme}
        code={children.props.children}
        language={
          matches && matches.groups && matches.groups.lang
            ? matches.groups.lang
            : ''
        }
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <>
            <LinePaddingContainer>
              {tokens.slice(0, -1).map((_, i) => (
                <LinePadding
                  $highlighted={highlightedLines.includes(i + 1)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`before-${i}`}
                />
              ))}
            </LinePaddingContainer>
            <ContainerInner>
              <pre className={className} style={style}>
                {tokens.slice(0, -1).map((line, i) => {
                  const lineProps = {
                    ...getLineProps({ line, key: i }),
                    $highlighted: highlightedLines.includes(i + 1),
                    $showLineNumbers: showLineNumbers,
                  };
                  return (
                    // eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-key
                    <Line {...lineProps}>
                      {showLineNumbers && <LineNumber> {i + 1} </LineNumber>}{' '}
                      {line.map((token, key) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading, react/jsx-key
                        <span {...getTokenProps({ token, key })} />
                      ))}
                    </Line>
                  );
                })}
              </pre>
            </ContainerInner>
            <LinePaddingContainer>
              {tokens.slice(0, -1).map((_, i) => (
                <LinePadding
                  $highlighted={highlightedLines.includes(i + 1)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`after-${i}`}
                />
              ))}
            </LinePaddingContainer>
          </>
        )}
      </Highlight>
    </Container>
  );
}

export default Pre;
