import React from 'react';
import Helmet from 'react-helmet';

import { useTypography } from 'providers/typography';
import favicon from 'assets/favicon.png';

interface Props {
  pageContext: any;
}

function HeadComponent({ pageContext }: Props): JSX.Element {
  const typography = useTypography();
  const og = Object.keys(pageContext.frontmatter?.meta?.og || {}).map(
    (property: string) => ({
      property,
      content: pageContext.frontmatter?.meta?.og?.[property],
    })
  );
  return (
    <Helmet>
      <title>
        {pageContext.frontmatter?.meta?.title || pageContext.frontmatter?.title}
      </title>
      {pageContext.frontmatter?.published === false && (
        <meta name="robots" content="noindex" />
      )}
      <meta
        name="description"
        content={pageContext.frontmatter?.meta?.description}
      />
      {og.map(({ property, content }) => (
        <meta property={`og:${property}`} content={content} key={content} />
      ))}
      <meta
        name="og:description"
        content={pageContext.frontmatter?.meta?.description}
      />
      <meta name="language" content={pageContext.langKey} />
      <link rel="icon" type="image/png" href={favicon} />
      <style>{typography.toString()}</style>
    </Helmet>
  );
}

export default HeadComponent;
