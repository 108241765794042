import React from 'react';
import styled, { useTheme } from 'styled-components';
import type Typography from 'typography';

const Container = styled.p<{
  $fontFamily: Typography['options']['headerFontFamily'];
  $fontSize: string;
  $lineHeight: string;
}>`
  ${({ $fontFamily, $fontSize, $lineHeight }) => `
    ${$fontFamily && `font-family: ${$fontFamily.join(', ')}`};
    font-size: ${$fontSize};
    font-weight: 300;
    line-height: ${$lineHeight};
    text-transform: uppercase;
  `}
`;

interface DateFormatOptions {
  day: 'numeric' | '2-digit';
  month: 'numeric' | '2-digit' | 'long' | 'short';
  year: 'numeric' | '2-digit';
}

const formatDate = (
  date: string,
  locale: string,
  options: Partial<DateFormatOptions> = {}
) =>
  new Date(date).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    ...options,
  });

interface Props {
  date: string;
  locale: string;
  options?: Partial<DateFormatOptions>;
}

function PublicationDate({ date, locale, options }: Props) {
  const typography = useTheme() as Typography;
  const { fontSize, lineHeight } = typography.adjustFontSizeTo('16px') as any;
  return (
    <Container
      $fontFamily={typography.options.headerFontFamily}
      $fontSize={fontSize}
      $lineHeight={lineHeight}
    >
      {formatDate(date, locale, options)}
    </Container>
  );
}

export default PublicationDate;
