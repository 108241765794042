import { createElement } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import * as Anchor from 'components/anchor';

interface Props {
  children: string | JSX.Element | Array<string | JSX.Element>;
  href?: string;
  to: string;
}

function Link(props: Props): JSX.Element {
  const { children, to, href = to } = props;
  switch (true) {
    case /^[#]/.test(href):
      return createElement(
        Anchor.Link,
        { ...props, id: href.replace(/^[#]/, '') },
        children
      );
    case /^[@]/.test(href):
      return createElement(
        Anchor.Target,
        { ...props, id: href.replace(/^[@]/, '') },
        children
      );
    case /^[/]/.test(href):
      return createElement(
        GatsbyLink,
        { ...props, to: href.replace(/[/]$/, '') },
        children
      );
    default:
      return createElement(
        'a',
        { href: to, target: '_blank', ...props },
        children
      );
  }
}

export default Link;
