import React, { createElement } from 'react';
import Gist, { GistProps } from 'react-gist';
import styled from 'styled-components';

const Container = styled.div``;

function GistContainer(props: GistProps) {
  return <Container>{createElement(Gist, props)}</Container>;
}

export default GistContainer;
