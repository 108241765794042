import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 64px hsla(0 0% 0% / 8%);
  overflow: hidden;
`;

const Controls = styled.div`
  background: var(--nord6);
  color: #bec4c6;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

const WindowButton = styled.span`
  border-radius: 50px;
  display: inline-block;
  height: 15px;
  margin: 0 2px;
  width: 15px;
`;

const CloseButton = styled(WindowButton)`
  background: var(--nord12);
`;

const MinimizeButton = styled(WindowButton)`
  background: var(--nord13);
`;

const MaximizeButton = styled(WindowButton)`
  background: var(--nord14);
`;

interface Props {
  children: ReactNode;
}

function BrowserFrame({ children }: Props) {
  return (
    <Container>
      <Controls>
        <MaximizeButton />
        <MinimizeButton />
        <CloseButton />
      </Controls>
      {children}
    </Container>
  );
}

export default BrowserFrame;
