import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faCircleUser,
  faPen,
  faCircleDot,
  faMoon,
  faSun,
  faEarthAmericas,
} from '@fortawesome/free-solid-svg-icons';

import { useNavigation } from 'providers/navigation';
import { useColorMode, ColorMode } from 'providers/colorMode';

const Container = styled.menu<{ mode: ColorMode }>`
  align-items: baseline;
  color: ${({ mode }) =>
    mode === ColorMode.LIGHT ? 'hsl(0, 0%, 60%)' : 'hsl(0, 0%, 95%)'};
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding: ${({ theme: { rhythm } }) => `${rhythm(1 / 2)} 1rem`};
  transition: 1s background-color ease-in-out;
  width: 100% !important;
`;

const Item = styled(Link)`
  background-image: none;
  color: inherit;
  font-size: 1.1rem;
  line-height: ${({ theme: { rhythm } }) => rhythm(1)};
  margin: 0 0.5rem;
  opacity: 0.9;
`;

const ItemActive = styled(Item)`
  border-bottom: 2px solid
    ${({ theme: { colorScheme } }) => colorScheme.th.border};
`;

const Button = styled.span`
  cursor: pointer;
  font-size: 1.1rem;
  margin: 0 0.5rem;
  opacity: 0.9;
`;

const Separator = styled.span`
  color: ${({ theme: { colorScheme } }) => colorScheme.th.border};
  position: relative;
  top: -2px;
`;

const getIcon = (identifier: string): IconDefinition =>
  ({ home: faCircleUser, blog: faPen }[identifier] || faCircleDot);

function Menu(): JSX.Element {
  const { menu, slug, translation } = useNavigation();
  const { mode, setMode } = useColorMode();

  const toggleColorMode = () => {
    setMode(mode === ColorMode.DARK ? ColorMode.LIGHT : ColorMode.DARK);
  };

  return (
    <Container mode={mode}>
      {menu.map((item) =>
        React.createElement(
          item.active ? ItemActive : Item,
          { key: item.url, to: item.url },
          <FontAwesomeIcon icon={getIcon(item.identifier.split('_')[0])} />
        )
      )}
      <Separator>&bull;</Separator>
      <Button onClick={toggleColorMode}>
        <FontAwesomeIcon icon={mode === ColorMode.DARK ? faSun : faMoon} />
      </Button>
      {translation &&
        React.createElement(
          Item,
          { key: slug, to: translation },
          <FontAwesomeIcon icon={faEarthAmericas} />
        )}
    </Container>
  );
}

export default Menu;
