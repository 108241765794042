import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

const Button = styled.div<{ $copied: boolean }>`
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 3px;
  color: ${({ $copied }) => ($copied ? 'lightgreen' : 'hsl(0, 0%, 80%)')};
  cursor: pointer;
  line-height: 1rem;
  padding: 5px;
  &:hover {
    color: ${({ $copied }) => ($copied ? 'lightgreen' : 'hsl(0, 0%, 70%)')};
  }
`;

function CopyButton({ value }: { value: string }) {
  const [copied, setCopied] = useState(false);
  let timer: NodeJS.Timeout;

  const onClickHandler = () => {
    if (typeof navigator !== 'undefined') {
      navigator.clipboard?.writeText(value);
      setCopied(true);
      timer = setTimeout(() => setCopied(false), 800);
    }
  };

  useEffect(() => () => clearTimeout(timer), []);

  return (
    <Button onClick={onClickHandler} $copied={copied}>
      {copied ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faCopy} />
      )}
    </Button>
  );
}

export default CopyButton;
