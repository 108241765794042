import nord from 'lib/colors/nord';

export default {
  C0: 'hsl(0, 0%, 10%)',
  C1: nord.C1,
  C2: 'hsl(0, 0%, 18%)',
  C3: 'hsl(0, 0%, 28%)',
  C4: 'hsl(0, 0%, 38%)',
  C5: '#4169E1',
  C6: 'hsl(0, 0%, 70%)',
  C7: 'hsl(0, 0%, 80%)',
  C8: 'hsl(0, 0%, 90%)',
  C9: 'hsl(0, 0%, 92%)',
  C10: 'hsl(0, 0%, 96%)',
  C11: 'hsl(0, 0%, 98%)',
  C12: 'hsl(0, 0%, 100%)',
};
