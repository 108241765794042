import React, { ComponentProps, FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { useColorMode, ColorMode } from 'providers/colorMode';
import BrowserFrame from 'components/browserFrame';

interface QueryResult {
  allFile: {
    nodes: Array<{ relativePath: string }>;
  };
}

function NoFrame({ children }: { children: JSX.Element; mode: ColorMode }) {
  return children;
}

const ContainerDefault = styled.div<{ $width: number; $margin: number }>`
  ${({ theme: { rhythm }, $margin, $width }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: ${rhythm($margin)} auto;
    max-width: 90%;
    position: relative;
    @media (min-width: 480px) {
      max-width: ${$width}%;
    }
  `}
`;

const ContainerForCaption = styled(ContainerDefault)`
  padding-bottom: ${({ theme: { rhythm } }) => rhythm(1)};
`;

const Caption = styled.p`
  bottom: -${({ theme: { rhythm } }) => rhythm(1)};
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 0;
  opacity: 0.5;
  position: absolute;
`;

const BoxFrame = styled.div<{ mode: ColorMode }>`
  ${({ theme: { colorScheme }, mode }) => `
     box-shadow: 0 0 ${
       mode === ColorMode.DARK
         ? '12px hsla(0 0% 0% / 8%)'
         : '36px hsla(0 0% 0% / 16%)'
     };
     ${
       mode === ColorMode.DARK
         ? `border: 1px solid ${colorScheme.hr.background};`
         : ''
     }
  `}
`;

interface Props {
  caption?: string;
  src: string;
  frame?: 'box' | 'none';
  width: number;
}

function Video({ caption, src, frame = 'none', width = 90 }: Props) {
  const { mode } = useColorMode();
  const Frame = {
    box: BoxFrame as FC<ComponentProps<typeof BrowserFrame>>,
    none: NoFrame,
  }[frame];
  const margin = {
    box: 4,
    none: 0,
  }[frame];

  const Container = caption ? ContainerForCaption : ContainerDefault;
  const file: any = useStaticQuery<QueryResult>(graphql`
    query {
      allFile(
        filter: {
          relativePath: { regex: "/.(mp4)/" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          relativePath
          publicURL
        }
      }
    }
  `).allFile.nodes.find(({ relativePath }) =>
    new RegExp(`${src}$`).test(relativePath)
  )!;
  return (
    <Container $margin={margin} $width={width}>
      <Frame mode={mode}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls width={662} style={{ display: 'block', width: '100%' }}>
          <source src={file.publicURL} />
        </video>
      </Frame>
      {caption && <Caption>{caption}</Caption>}
    </Container>
  );
}

export default Video;
