import colors from 'lib/colors/minimal';
import type { ColorScheme } from 'providers/colorScheme';

const scheme: ColorScheme = {
  body: {
    color: colors.C7,
    background: 'hsl(230, 45%, 14%)',
  },
  headers: {
    color: colors.C7,
  },
  hr: { background: colors.C2 },
  a: {
    color: 'hsla(205, 70%, 73%, .9)',
    code: {
      background: 'hsla(0, 0%, 100%, .2)',
      hover: {
        background: 'hsla(0, 0%, 100%, .2)',
      },
    },
  },
  code: {
    background: '#383d4a',
  },
  note: {
    background: 'hsl(60, 90%, 83%)',
    color: colors.C6,
  },
  th: {
    border: 'hsla(0,0%,100%,0.4)',
  },
  td: {
    border: 'hsla(0,0%,100%,0.3)',
  },
  blockquote: {
    border: 'hsl(0, 0%, 18%)',
    background: 'hsla(0, 0%, 0%, 0.2)',
  },
  section: {
    background: {
      darker: 'hsl(230, 43%, 18%)',
      lighter: 'hsl(230, 45%, 14%)',
    },
  },
  pre: {
    background: 'hsla(0, 0%, 100%, .04)',
    color: colors.C7,
    highlighted: {
      background: 'hsla(0, 0%, 100%, .06)',
    },
  },
  location: {
    color: colors.C6,
    marker: { color: colors.C5 },
  },
};

export default scheme;
