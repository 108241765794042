import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

import Avatar from 'components/avatar';

const Marker = styled(FontAwesomeIcon)`
  ${({ theme: { colorScheme } }) => `
    color: ${colorScheme.location.marker.color} 
  `};
`;

const Location = styled.p`
  ${({ theme: { colorScheme } }) => `
    color: ${colorScheme.location.color} 
  `};
`;

interface Props {
  children: ReactNode | ReactNode[];
}

function AboutMe({ children }: Props) {
  return (
    <Avatar centerImage>
      {children}
      <Location>
        <Marker icon={faLocationDot} />{' '}
        <em>
          Montreal, Canada · <small>GMT -4</small>
        </em>
      </Location>
    </Avatar>
  );
}

export default AboutMe;
